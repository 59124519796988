import firebase from "firebase/app"
import "firebase/database"
import "firebase/auth"

const config = {
  apiKey: "AIzaSyAc9dsdGDFYZFvsjZqcjocJ0Mv4xhxhKfg",
  authDomain: "ticktockinc.firebaseapp.com",
  databaseURL: "https://ticktockinc.firebaseio.com",
  projectId: "ticktockinc",
  storageBucket: "",
  messagingSenderId: "372623661535",
  appId: "1:372623661535:web:8362f67a4bf19e69",
}

if (!firebase.apps.length) {
  firebase.initializeApp(config)
}

let db, auth

if (typeof window !== "undefined") {
  db = firebase.database()
  auth = firebase.auth()
}

export { db, auth }
